<template>
    <!-- 弹框 抗疫事迹 -->
        
    <el-dialog
        class="dialog_Event"
        :visible.sync="dialogVisible_events"
        width="630px"
        height="547px"
        center
        @close="dialog_Close"
    >
        
        <!-- <span slot="footer" class="dialogFooter"></span> -->
        <div class="diaE_box" v-if="data_type=='company'">
            <div class="top_left">
                <span>{{$t('bh_Num')}}{{item.number}}</span>
                <!-- <div>
                    <div  v-if="item.erweima==''">{{$t('erweiCode')}}</div>
                    <img v-if="item.erweima!=''" :src="item.erweima" alt="">
                </div> -->
            </div>
            <img class="logo" :src="item.logo?item.logo:defaultLogo" alt="">
            <h1>{{item.company_name_en | priorFormat(item.company_name_zh,LOCALE)}}</h1>
            <div class="address">
                <p>
                    <span>{{ item.country | countryCityFormat( item.region_en,item.region_zh,LOCALE)}}</span>
                </p>
                <p>
                    <span>{{$t('Booth')}}：{{item.booth_no | textFormat}}</span>
                </p>
            </div>
            <div class="paiming">
                <div>
                    <!-- 排名逻辑 没结束之前显示本周排名 结束之后显示总排名 -->
                    <p >{{is_ranking_end?$t('Overall_Ranking'):$t('CurrentRanking')}}：{{item.single_ranking | rankNum(item.votes)}}</p>
                    <voteBtn
                        :index='parent_index'
                        :number='item.votes'
                        :voted_id="item.company_id"
                        :vote_type='vote_type'
                    ></voteBtn>
                </div>
                <div :class="{'is_cursor_sty':is_cursor }" >
                    <p  @click="hand_top(1,'company')" v-if="show_ranking.first" >{{$t('First_week_ranking')}}：{{item.single_ranking_one | rankNum(item.votes) }}</p>
                    <p  @click="hand_top(2,'company')" v-if="show_ranking.second">{{$t('Second_week_ranking')}}：{{item.single_ranking_two | rankNum(item.votes)}}</p>
                    <p  @click="hand_top(3,'company')" v-if="show_ranking.thrid">{{$t('third_week_ranking')}}：{{item.single_ranking_three | rankNum(item.votes)}}</p>
                </div>
            </div>
            <div class="events">
                <h1>{{type_lable}}：</h1>

                <div v-if="vote_type == 1">{{item.profile_en | priorFormat(item.profile_zh,LOCALE)}}</div>
                <div v-if="vote_type == 2">{{item.extend | textFormat}}</div>
            </div>
            <div class="imgbox">
                <div class="item fl videoitem" v-if="item.video_list!== undefined && item.video_list.length>0">
                    <i class="icon iconfont iconplay video_play"  @click="openVideoPlay(item.video_list[0])"></i>
                    <el-image
                        @click="openVideoPlay(item.video_list[0])"
                        style="width: 124px; height: 86px"
                        :src="item.video_list[0].cover_url"
                        fit="scale-down"></el-image>
                </div>
               
                <div class="item fl" v-for="(it,index) in item.pic_list" :key="index">
                    <el-image
                        style="width: 124px; height: 86px"
                        :src="it.url"
                        :preview-src-list="[it.url]"
                        fit="scale-down"></el-image>
                </div>
                    

            </div>
        </div>

        <div class="diaE_box people_box" v-if="data_type=='people'">
            <div class="top_left">
                <span>{{$t('bh_Num')}}{{item.number}}</span>
                <!-- <div>
                    <div  v-if="item.erweima==''">{{$t('erweiCode')}}</div>
                    <img v-if="item.erweima!=''" :src="item.erweima" alt="">
                </div> -->
            </div>
            <img class="logo" :src="item.avatar?item.avatar:DEFAULT_AVATAR" style="border-radius: 50%" alt="">
            <div class="user_job">
                <span>{{item.user_name_en | priorFormat(item.user_name_zh,LOCALE)}}</span>
                <span>{{item.job_title_en | priorFormat(item.job_title_zh,LOCALE)}}</span>
            </div>
            <h1 class="company_p">{{item.company_name_en | priorFormat(item.company_name_zh,LOCALE)}}</h1>
            <voteBtn
                :index='parent_index'
                :number='item.votes'
                :voted_id="item.user_id"
                :vote_type='vote_type'
            ></voteBtn>

            <div class="paiming">
                <div>
                    <!-- 当前排名 --> 
                    <p >{{is_ranking_end?$t('Overall_Ranking'):$t('CurrentRanking')}}：{{item.overall_ranking | rankNum(item.votes)}}</p>
                </div>
                <div :class="{'is_cursor_sty':is_cursor }">
                    <p @click="hand_top(1,'people')" v-if="show_ranking.first">{{$t('First_week_ranking')}}：{{item.first_week_ranking | rankNum(item.votes)}}</p>
                    <p @click="hand_top(2,'people')" v-if="show_ranking.second">{{$t('Second_week_ranking')}}：{{item.second_week_ranking | rankNum(item.votes)}}</p>
                    <p @click="hand_top(3,'people')" v-if="show_ranking.thrid">{{$t('third_week_ranking')}}：{{item.third_week_ranking | rankNum(item.votes)}}</p>
                </div>
            </div>
            <div class="events_people">
                <h1>{{type_lable}}：</h1>
                <div>{{item.extend | textFormat}}</div>
            </div>
            <div class="imgbox">
                <div class="item fl videoitem" v-if="item.video_list!== undefined && item.video_list.length>0" >
                    
                    <i class="icon iconfont iconplay video_play"  @click="openVideoPlay(item.video_list[0])"></i>
                    <el-image
                        @click="openVideoPlay(item.video_list[0])"
                        style="width: 124px; height: 86px"
                        :src="item.video_list[0].cover_url"
                        fit="scale-down"></el-image>
                </div>
                <div class="item fl" v-for="(it,index) in item.picture_list" :key="index">
                    <el-image
                        style="width: 124px; height: 86px"
                        :src="it.url"
                        :preview-src-list="[it.url]"
                        fit="scale-down"></el-image>
                </div>
                    

            </div>
        </div>
        <videoPlay ref="allvideoplay_dialog"></videoPlay>
    </el-dialog>
</template>
<script>
import voteBtn from '~exp/components/rankingList/voteBtn'
import videoPlay from '@/baseComponents/videoPlay'
import "@/baseAssets/icon/iconfont.css";
export default {
    props:['navValue','type'],
    components:{voteBtn,videoPlay},
    data(){
        return{
            parent_index:"",
            type_lable:"",
            is_ranking_end:"",
            show_ranking:{},
            row:{},
            votes_ed:false,
            data_type:"",
            item: {
                votes: ''
            },
            dialogVisible_events:false,
            defaultLogo:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/logo.png',
            vote_type: 1,
            is_cursor:false,
        }
    },
    filters:{
        rankNum(num,votes){
            if(!num || num==0 || votes==0){
                return '-'
            }else{
                return num
            }
        }
    },
    mounted(){
        if(this.$route.name=='list'){
            this.is_cursor=true
        }
        //查看三周排行榜的日期  开始3-29
        let week_1=this.$moment('2021-4-5').startOf('day').unix()
        let week_2=this.$moment('2021-4-12').startOf('day').unix()
        let week_3=this.$moment('2021-4-17').startOf('day').unix()
        let rank_endTime=this.$moment('2021-4-17').startOf('day').unix()
        let now_time=this.$moment().unix()
        this.show_ranking.first=now_time>week_1?true:false
        this.show_ranking.second=now_time>week_2?true:false
        this.show_ranking.thrid=now_time>week_3?true:false
        this.is_ranking_end=now_time>rank_endTime?true:false

        this.$GLOBALEVENT.$on("VOTESUCCESS", (res) => {
            this.item.votes = res.votes
            this.votes_ed=true
        })
        this.$GLOBALEVENT.$on("OPENCOMPANY", (row) => {
            this.row=row
            
            this.parent_index=row.index
            if (row.type.query.type=="people" && row.type.query.value == 1) {
                this.vote_type = 3
                this.type_lable=this.$t('Protest') //抗议实际"company"
            }
            if (row.type.query.type=="people" && row.type.query.value == 2) {
                this.vote_type = 4
                this.type_lable=this.$t('Personal_profile') //个人简介
            }
            if (row.type.query.type=="people" && row.type.query.value == 3) {
                this.vote_type = 5
                this.type_lable=this.$t('Personal_profile') //个人简介
            }
            if (row.type.query.type=="company" && row.type.query.value == 1) {
                this.vote_type = 1
                this.type_lable=this.$t('companyDesc') //企业简介
            }
            if (row.type.query.type=="company" && row.type.query.value == 2) {
                this.vote_type = 2
                this.type_lable=this.$t('Protest') //抗议实际
            }
            this.getCompanyDetail(row)
            this.dialogVisible_events=row.show
        });
    },
    destroyed(){
        this.$GLOBALEVENT.$off("OPENCOMPANY")
        this.$GLOBALEVENT.$off("VOTESUCCESS")
    },
    methods:{
         //获取公司报名详情
        async getCompanyDetail(row) {
           
            this.loading = true
            let res
            if(row.type.query.type=="people"){
                this.data_type='people'
                res =  await this.$store.dispatch('register/vote_user_apply_detail', {apply_id:row.params.id,apply_type:row.type.query.value})
            }else{
                this.data_type='company'
                let params = {}
                params.company_id = row.params.company_id
                params.meeting_id =this.MEETING_ID
                params.apply_type=row.type.query.value
                res = await this.$store.dispatch('register/companyRegisterDetail', params)
            }
            if (res.success) {
                this.item=res.data
                this.loading = false
            } else {
                this.loading = false
            }
        },
        openVideoPlay(it){
            this.$refs.allvideoplay_dialog.startPlay(it.aliyun_id)
        },
        dialog_Close(){
            if(this.votes_ed){
                this.votes_ed = false
                this.$GLOBALEVENT.$emit("DIALOFGCOMPANYCLOSE",this.row)
            }
        },
        //  条状到对应的 1 2 3 榜
        hand_top(index,type){
            if(!this.is_cursor){
                return
            }
            this.$router.push({
                path:'/overallRankingDetail',
                query:{
                    parameter:this._encode({
                        week:index,
                        rank_id:this.navValue.id
                    })
                }
            })
        }
    },
}
</script>
<style lang="less" scoped>
    .dialog_Event{
        /deep/ .el-dialog {
            box-shadow:none;
            // height: 679px;
        }
        /deep/ .el-dialog__header,/deep/ .el-dialog__footer{
            height: 0;
            padding: 0;
        }
        /deep/ .el-dialog__body{
            padding-left: 36px;
            padding-right: 36px;
        }
        .diaE_box{
            position: relative;
            display:flex;
            flex-direction: column;
            align-items: center;
            .top_left{
                cursor: pointer;
                position: absolute;
                width: 78px;
                height: 158px;
                background: linear-gradient(180deg, #FFFADA 0%, rgba(255, 252, 231, 0) 100%);
                border-radius: 0px 0px 4px 4px;
                top: -26px;
                left: -15px;
                display: flex;
                flex-direction: column;
                align-items: center;
                span{
                    margin-top: 19px;
                    padding-bottom: 27px;
                    font-size: 14px;
                    font-weight: 800;
                    color: #FF9905;
                }
                div{
                    width: 60px;
                    height: 60px;
                    line-height: 60px;
                    text-align: center;
                    background: #F2F2F2;
                }
            }

            .logo{
                width: 108px;
                height: 108px;
                margin-top:27px ;
                margin-bottom:12px ;
            }
            h1{
                width: 480px;
                text-align: center;
                font-size: 16px;
                font-weight: 800;
                color: #333333;
                line-height: 22px;
            }
            .address{
                padding-top:8px;
                display: flex;
                p:first-child{                   
                    font-size: 14px;
                    font-weight: 400;
                    color: #7D8695;
                }
                p:nth-child(2){    
                    padding-left: 24px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #DD9740;
                }
            }
            .paiming{
                width: 100%;
                height: 93px;
                background: linear-gradient(270deg, rgba(238, 238, 238, 0) 0%, #EEEEEE 51%, rgba(238, 238, 238, 0) 100%);
                padding: 16px;
                margin: 16px;
                div{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                div:nth-child(1){
                    margin-bottom: 24px;
                    p:first-child{
                        font-size: 14px;
                        font-weight: 800;
                        color: #1F292E;
                        line-height: 100%;
                        padding-right: 53px;

                    }
                    p:nth-child(2){
                        width: 119px;
                    }
                }
                div:nth-child(2) p{
                    height: 17px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #1F292E;
                    line-height: 17px;
                    padding-left: 24px;
                }
            }
            .events{
                
                h1{
                    width: 100%;
                    text-align: center;
                    margin-bottom: 8px;
                }
                div{
                    font-size: 12px;
                    font-weight: 400;
                    color: #1F292E;
                    line-height: 22px;
                        overflow-y: scroll
                }
            }
            .imgbox{
                padding-top: 16px;
                width: 100%;
                display: flex;
                img{
                    width: 124px;
                    height: 86px;   
                    margin-right: 21px ;
                }
                 .is_right{
                    margin-right:0px !important;
                }
            }
           
        }
    }
    .people_box{
        .user_job{
            margin-top: 12px;
            margin-bottom:12px ;
            display: flex;
            text-align: center;
            span:first-child{
                font-size: 18px;
                font-weight: 800;
                color: #333333;
                padding-right: 8px;
                height: 25px;
                line-height: 25px;
            }
            span:nth-child(2){
                font-size: 14px;
                font-weight: 400;
                color: #7D8695;
                height: 25px;
                line-height: 25px;
            }
        }
        .company_p{
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            margin-bottom:12px ;
        }
        .events_people{
            width: 100%;
            h1{
                margin-bottom:12px ;
            }
            h1,div{
                text-align: left;
            }
        }
    }
    .videoitem{
        position: relative;
        cursor: pointer;
        .video_play{
            position: absolute;
            top: 36px;
            left: 53px;
            color: #fff;
            z-index: 20;
        }
    }
    .is_cursor_sty{
        cursor: pointer;
    }
</style>