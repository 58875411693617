<template>
  <div class="voteBtn">
    <!--<el-popover-->
    <!--placement="top"-->
    <!--trigger="manual"-->
    <!--popper-class="voteBtn"-->
    <!--v-model="visible"-->
    <!--&gt;-->
    <!--<div v-show="voteResult" class="voteResult">+1</div>-->
    <!--<div v-show="!voteResult" class="voteResultFalse">-->
    <!--<div class="voteResultIcon">!</div>-->
    <!--<div class="voteResultFalseText">今日网页投票次数已用完</div>-->
    <!--</div>-->
    <!--<el-button-->
    <!--slot="reference"-->
    <!--:loading="btnLoading"-->
    <!--class="btn"-->
    <!--type="text"-->
    <!--:disabled="disabled"-->
    <!--@click="subBtn"-->
    <!--&gt;-->
    <!--<img class="xinxin" src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/xinxin.png"></img>-->
    <!--{{ number }}-->
    <!--</el-button>-->
    <!--</el-popover>-->
    <el-button
      slot="reference"
      class="btn"
      :loading="btnLoading"
      type="text"
      :disabled="disabled"
      @click="subBtn"
    >
      <img class="xinxin" src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/xinxin.png" />
      {{ number }}
    </el-button>
  </div>
</template>
<script>
export default {
  props: {
    number: {
      type: Number | String, //投票数
      default: "22",
    },
    voted_id: {
      //  被投票公司ID或用户ID
      type: Number | String,
      default: "",
    },
    vote_type: {
      //投票类型，1-物流人气企业，2-抗疫贡献企业，3-抗疫英雄，4-物流型男，5-物流女神'
      type: Number | String,
      default: "",
    },
    index: {
      type: Number | String, // 点击的是数组的第几个数据
      default: "",
    },
  },
  data() {
    return {
      btnLoading: false,
      disabled: false,
      visible: false,
      timer: null,
      voteResult: false, //  投票结果，显示是否投票成功
    };
  },
  methods: {
    subBtn() {
      this.checkSignEnrollExhibitor({
        todo: () => {
          let num = this.voteTimerFn();
          if (num === 1) {
            this.$message.warning(this.$t('VotingWillOpenOnMarch29'));
          } else if (num === 2) {
            this.voteFn();
          } else if (num === 3) {
            this.$message.warning(this.$t("VotingIsClosed"));
          }
        },
        type: "sign",
      });
    },
    openTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.timer = setInterval(() => {
        this.visible = false;
        clearInterval(this.timer);
        this.timer = null;
      }, 1000);
    },
    voteTimerFn() {
      // 当前时间
      let nowTime = this.$moment(new Date()).unix();
      // 投票未开始时间
      let startTime = this.$moment("2021-03-29").startOf("day").unix();
      let endTime = this.$moment("2021-04-17").startOf("day").unix();
      if (nowTime < startTime) {
        return 1;
      }
      if (nowTime > startTime && nowTime < endTime) {
        return 2;
      }
      if (nowTime > endTime) {
        return 3;
      }
    },
    async voteFn() {
      // let todaytime = this.$moment().startOf('day').unix()
      let params = {
        user_id: this.USER_INFO.id,
        voted_id: this.voted_id,
        vote_type: this.vote_type,
        platform: "pc",
      };
      this.btnLoading = true;
      let res = await this.$store.dispatch("rankingList/handleVote", params);
      this.btnLoading = false;
      if (res.success) {
        // this.visible = true
        this.$message.success(this.$t("voteScuess"));
        // this.openTimer()
        this.$GLOBALEVENT.$emit("VOTESUCCESS", {
          votes: res.data,
          index: this.index,
        });
      } else if (res.errorcode == 1025) {
        this.$message.warning(this.$t("voteEnd"));
      }
    },
  },

  destroyed() {
    this.timer = null;
  },
};
</script>
<style lang="less" scoped>
.voteBtn {
  display: inline-block;
  .btn {
    min-width: 100px;
    border: none;
    padding: 8px 18px 10px;
    height: 30px;
    background: linear-gradient(180deg, #fdd689 0%, #ff9905 100%);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    .xinxin {
      margin-right: 10px;
    }
  }
}
</style>
<style lang="less">
.voteBtn {
  .voteResult {
    text-align: center;
    font-weight: 800;
    color: #1f292e;
  }
  .voteResultFalse {
    display: flex;
    .voteResultIcon {
      width: 14px;
      height: 14px;
      background: #ff9c0c;
      border-radius: 7px;
      color: #ffffff;
      text-align: center;
      margin-right: 4px;
    }
    .voteResultFalseText {
      font-size: 12px;
      color: #1f292e;
    }
  }
}
</style>