<template>
    <div class="nav_box content_area" :style="{width:width}">
        <span v-for="(item,i) in navlist" :key='i'  :class="{active:active_index==item.id}" @click="handClick(i,item)"> <i v-if="active_index==item.id && loading" class="el-icon-loading"></i> {{item.lable}}</span>
    </div>
</template>
<script>
export default {
    props:{
        navlist: {
            type: Array,
            required: true,
            default: function () {
                return []
            }
        },
        navlist_active:{
            type: Number| String,
            default:0
        },
        width:{
            type: String,
            default:'100%'
        },
        loading:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{
            active_index:this.navlist_active
        }
    },
    watch:{
        navlist_active(val){
            this.active_index=val
        }
    },
    methods:{
        handClick(index,row){
            if(this.loading){
                return
            }
            this.active_index=row.id
            this.$emit('navEvents',row)
            
        }
    }    
}
</script>
<style lang="less" scoped>
    .nav_box{
        background: #FFFFFF;
        box-shadow: 0px 3px 11px 0px rgba(18, 58, 99, 0.13);
        border-radius: 63px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        span{
            cursor: pointer;
            font-size: 16px;
            font-weight: 400;
            color: #1F292E;
            min-width: 191px;
            max-width: 350px;
            height: 42px;
            line-height: 42px;
            border-radius: 63px;
            text-align: center;
            overflow: hidden;
            white-space: nowrap !important;
            text-overflow: ellipsis;
            padding: 0 10px;
        }
        .active{
            background: #027FFF;
            font-size: 16px;
            font-weight: 800;
            color: #FFFFFF;
        }
    }
    .loading_cursor{
        cursor: progress;
    }
</style>