<template>
  <div class="banner" :class="LOCALE">
    <div class="btns">
      <span class="join" @click="join">
        {{ $t('nowJoin') }}
      </span>
      <span class="view" @click="view">
        {{ $t('viewRanking') }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "rankingBanner",
  methods: {
    join() {
      this.$router.push('/activity')
    },
    view() {
      // 事件限制 （只在list页面）
      let data=this.$moment('2021-3-29').startOf('day').unix()
      if (this.$moment().unix() < data && this.$route.fullPath=='/list' ){
          this.$message({
              message: this.$t('VotingWillOpenOnMarch29'),
              type: 'warning'
          });
          return
      }


      this.$router.push('/overallRankingList')
    }
  }
}
</script>

<style scoped lang="less">
.banner {
  position: relative;
  padding-top: 18.22%;
  background: url('https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/banner.png');
  background-size: 100% 100%;
  &.en{
    background: url('https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/banneren.png');
    background-size: 100% 100%;
  }

  .btns {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    span {
      cursor: pointer;
      position: absolute;
      bottom: 8%;
      width: 150px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      background: linear-gradient(90deg, #F4F4F4 0%, #FFFFFF 100%);
      border-radius: 20px;
      font-size: 16px;
      font-weight: 800;
      color: #FF4B35;
    }

    .join {
      left: 40.8%;
      background: linear-gradient(90deg, #FAD961 0%, #FFE549 100%);
    }

    .view {
      left: 52.5%;
    }
  }
}
</style>
